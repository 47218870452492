/* global graphql */

import React from 'react';
import TemplateWrapper from '../../components/TemplateWrapper';

class SuccessPage extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { location } = this.props;

    return (
      <TemplateWrapper location={location}>
        <main className="SuccessPage">
          <section className="Section SuccessPage-header">
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-xs-14 col-md-12 col-md-offset-1 col-lg-10 col-lg-offset-1">
                    <div className="SuccessPage-card">
                      <h2 className="Heading Heading-h2 dark SuccessPage-card-title">Magic is all around us.
                        And you're the first to see!</h2>
                      <p className="BodyText BodyText-B1 dark SuccessPage-card-p">Magic Alpha for Developers is now available open source on <a href="https://magic-network.github.io" onClick={()=>window.analytics.track('Clicked Github Link',{component:'successPage'})} target="_blank" rel="noopener" className="Btn BtnText BtnText-black dark font-regular">GitHub</a>.</p>
                      <p className="BodyText BodyText-B1 dark SuccessPage-card-p">Grab the alpha for Magic Client and Magic Agent today. Additional Magic products - including native clients for WiFi, Cellular and 5G will begin launching throughout 2019. Keep it locked.</p>
                      {/*eslint-disable-next-line*/}
                      <p className="BodyText BodyText-B1 dark SuccessPage-card-p">Follow us on <a href="https://twitter.com/helloitsmagic" target="_blank" rel="noopener" onClick={()=>window.analytics.track('Clicked Twitter Link',{component:'successPage'})} className="Btn BtnText BtnText-black dark font-regular">Twitter</a> and our <a href="https://medium.com/helloitsmagic" target="_blank" onClick={()=>window.analytics.track('Clicked Blog Link',{component:'successPage'})} rel="noopener" className="Btn BtnText BtnText-black dark font-regular">blog</a> and dive into more details with our Introducing Magic document.</p>
                      <div className="SuccessPage-card-buttons">
                        {/*eslint-disable-next-line*/}
                        <a href="https://magic-network.github.io" onClick={()=>window.analytics.track('Clicked Github Link',{component:'successPage'})} target="_blank" className="BtnFilled BtnFilled-large purple uppercase"><span>Get the Alpha</span></a>
                        {/*eslint-disable-next-line*/}
                        <a href="https://mgc.co/intro-doc" onClick={()=>window.analytics.track('Downloaded Intro Doc',{component:'successPage'})} target="_blank" className="BtnGhost BtnGhost-large purple uppercase"><span>Read the introduction</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </TemplateWrapper>
    );
  }

}

export default SuccessPage;

